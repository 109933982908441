<template>
  <div class="flc">
      <div class="video-Box" v-if="!imgSrc">
        <video id="videoCamera" muted webkit-playsinline='true' playsinline='true' x5-video-player-type="h5-page" style="box-shadow: darkgrey 0 0  30px 5px ;"></video>
        <canvas style="display:none; " id="canvasCamera"></canvas>
      </div>
      <div class="img_bg_camera" v-if="imgSrc">
          <p style="color: #000000;">图片显示</p>
          <img :src="imgSrc" alt="" @click="imgSrc = null" class="tx_img" style="box-shadow: darkgrey 0 0  30px 5px ;" v-if="imgif">
      </div>
      <div class="bommen" style="margin-top: 50px;">
        <button @click="getCompetence">打开摄像头</button>
        <button @click="closeCarama">关闭摄像头</button>
        <button @click="setImage">拍照</button>
      </div>
  </div>
</template>

<script>
export default {
  data() {
      return {
          imgif: false,
          videoWidth: document.documentElement.clientHeight,
          videoHeight: document.documentElement.clientWidth,
          imgSrc: '', //图片src
          canvasCamera: null,
          contextCamera: null,
          videoCamera: null,
      }
  },
  mounted() {
  },
  methods: {
      // 打开摄像头
      getCompetence() {
          this.canvasCamera = document.getElementById('canvasCamera')
          this.contextCamera = this.canvasCamera.getContext('2d')
          // 关掉抗锯齿
          this.contextCamera.mozImageSmoothingEnabled = false;
          this.contextCamera.webkitImageSmoothingEnabled = false;
          this.contextCamera.msImageSmoothingEnabled = false;
          this.contextCamera.imageSmoothingEnabled = false;
          this.videoCamera = document.getElementById('videoCamera')
          var _this = this
          // 旧浏览器可能不支持mediaDevices
          if (navigator.mediaDevices === undefined) {
              navigator.mediaDevices = {}
          }
          // 使用getUserMedia
          // 缺少getUserMedia
          if (navigator.mediaDevices.getUserMedia === undefined) {
              navigator.mediaDevices.getUserMedia = function (constraints) {
                  // 首先获取现存的getUserMedia
                  var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia
                  // 有些浏览器不支持
                  // 保持接口一致
                  if (!getUserMedia) {
                      return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
                  }
                  // 使用Promise将调用包装到旧的navigator.getUserMedia
                  return new Promise(function (resolve, reject) {
                      getUserMedia.call(navigator, constraints, resolve, reject)
                  })
              }
          }
          var constraints = {
              audio: false,
              video: {
                width: { min: 2600, ideal: 1280, max: 2600 },
                height: { min: 1170, ideal: 720, max: 1170 },
                  facingMode: { exact: "environment" },
                  transform: 'scaleX(-1)'
              }
          }
          navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
              // 新的浏览器使用srcObject
              if ('srcObject' in _this.videoCamera) {
                  _this.videoCamera.srcObject = stream
              } else {
                  // 避免在新的浏览器中使用它，因为它正在被弃用。
                  _this.videoCamera.src = window.URL.createObjectURL(stream)
              }
              _this.videoCamera.onloadedmetadata = function (e) {
                  _this.videoCamera.play()
              }
          }).catch(err => {
              console.log('没有开启摄像头权限或浏览器版本不兼容',err)
          })
      },
      closeCarama() {
        this.imgSrc = ''
          this.videoCamera.srcObject.getTracks()[0].stop()
      },
      //  绘制图片
      setImage() {
        const ratio = getPixelRatio(this.contextCamera);
        function getPixelRatio(context) {
        const backingStore =
            context.backingStorePixelRatio ||
            context.webkitBackingStorePixelRatio ||
            context.mozBackingStorePixelRatio ||
            context.msBackingStorePixelRatio ||
            context.oBackingStorePixelRatio ||
            context.backingStorePixelRatio || 1;
            return (window.devicePixelRatio || 1) / backingStore;
        }
        this.canvasCamera.width = 300 * ratio/2;
        this.canvasCamera.height = 500 * ratio/2;
        this.imgif = true
        // 点击，canvas画图
        this.contextCamera.drawImage(this.videoCamera, 5* ratio, 5* ratio, 260* ratio/2, 460* ratio/2)
        
        var image = this.canvasCamera.toDataURL('image/png',2.0)
        this.imgSrc = image
      },

  }
}
</script>

<style scoped lang="scss">




.flc {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
.video-Box{
    width: 100%;
    height: 100%;
    video{
        width: 100%;
        height: 100%;
    }
    canvas{

    }
}
.bommen{
    position: fixed;
    bottom: .5rem;
    
}

}

// .face {
// width: 400px;
// height: 450px;
// position: relative;
// }

// .face .face-img {
// width: 400px;
// height: 450px;
// position: absolute;
// left: 0px;
// top: 0px;
// }

// .face .face-content {
// width: 310px;
// height: 322px;
// position: absolute;
// top: 64px;
// left: 45px;
// }

// .face .face-content #video {
// object-fit: fill;
// }

// .step {
// text-align: center;
// margin-top: 30px;
// }

.step .step-ul .step-li .step-title {
font-size: 16px;
color: #3875FE;
padding-bottom: 10px;
}

.step .step-ul .step-li .step-title .step-titleimg {
height: 26px;
width: 26px;
margin-left: 20px;
cursor: pointer;
}

.step .step-ul .step-li .step-img {
height: 150px;
width: 150px;
border: 3px solid #eecfa1;
border-radius: 50%;
}

button {
    width: 80px;
    height: 40px;
    background-color:#3875FE;
    border-radius: 10px;
  }
.step .step-ul .step-li .step-img img {
height: 100%;
width: auto;
}
</style>
